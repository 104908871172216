<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
  </div>
</template>

<script>
export default {
  name: 'Index'
}
</script>

<style scoped>

</style>
